/* You can add global styles to this file, and also import other style files */
html, body, app-root {
  height: 100%;
  width: 100%;
  margin: 0;

  font-size: 14px;
}

body {
  background-color: rgba(128, 128, 128, 0.2) !important;
}
